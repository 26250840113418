// use this to debug to the logs instead of sending to GTM
// (mostly development only, or Storybook, etc)
export default class GTMAnalyticsLogger {
	pushToDataLayer(args) {
		try {
			console.log("Analytics event: ", args);
		} catch (err) {
			console.error(err);
		}
	}
}
