import PropTypes from "prop-types";
const Button = ({
  appearance = "primary",
  children,
  ...restProps
}) => {
  return <button {...restProps} className={`${restProps.className ? restProps.className : ""} ${appearance}`} data-sentry-component="Button" data-sentry-source-file="index.jsx">
			{children}
		</button>;
};
Button.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.any
};
export default Button;