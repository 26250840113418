import * as Sentry from "@sentry/nextjs";
import safeDataLayerPush from "./safeDataLayerPush";

class GTMDataLayerPusher {
	pushToDataLayer(args) {
		try {
			//Pushes data to the Google Tag Manager dataLayer to be used in Google Analytics
			safeDataLayerPush(args)
		} catch (err) {
			console.error(err);
			Sentry.captureException(err)
		}
	}
}

export default GTMDataLayerPusher;
